.placeholder-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.placeholder-card-content h2 {
    font-size: var(--font-size-24);
    font-weight: var(--font-weight-400);
    margin: 0;
}