.transcripts-view .transcripts-view-header {
  display: flex;
  align-items: center;
  gap: var(--constant-spacing-16);
}

.transcripts-view .transcripts-view-header h2 {
  margin: 0;
}

.transcripts-view .transcripts-view-body {
  margin-top: var(--constant-spacing-16);
}

.subjects-table {
  width: 100%;
  border-collapse: collapse;
}

.subjects-table th,
.subjects-table td {
  border: 1px solid var(--border-color);
  padding: var(--scaling-spacing-8);
}

.subjects-table .course-cell-data {
  display: flex;
  flex-wrap: wrap;
  gap: var(--constant-spacing-8);
  justify-content: center;
}

.subjects-table .course-cell-data .course-info {
  background-color: var(--container-background-color);
  flex-grow: 1;
}