.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--constant-spacing-16);
    min-height: 100vh;;
    background-color: #fff;
  }
  
  .login-container-title {
    max-width: 40vw;
    margin-top: auto;
    text-align: center;
    padding: var(--constant-spacing-12);
  }
  
  .login-container h3 {
    font-size: var(--font-size-24);
    font-weight: 600;
  }
   
  .login-box {
    width: 40vw;
    height: 30vh;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);
    border: 1px solid black;
  }