.school-container {
  display: flex;
  align-items: center;
  gap: var(--scaling-spacing-8);
  font-size: var(--font-size-14);
  margin-top: var(--scaling-spacing-16);
}

.data-status {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-700);
}

.data-status p {
  margin: 0;
}

.data-status span {
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-400);
}