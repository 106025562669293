.tag {
  line-height: 1.2;
  padding: var(--scaling-spacing-8);
  border-radius: var(--border-radius-4);
  border: 1px solid var(--divider-50);
  display: inline-block;
}

.tag.small {
  font-size: var(--font-size-12);
}

.tag.medium {
  font-size: var(--font-size-16);
}

.tag p {
  margin: 0;
}

.tag.pending {
  background-color: var(--divider-10);
  border-color: var(--divider-50);
}

.tag.progress {
  background-color: var(--warning-light);
  border-color: var(--warning-dark);
}

.tag.completed {
  background-color: var(--success-light);
  border-color: var(--success-dark);
}

.tag.info {
  background-color: var(--info-dark);
  border-color: var(--info-dark);
  color: var(--text-white);
}