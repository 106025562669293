.transcript-details-accordion-header {
    display: flex;
    gap: var(--scaling-spacing-8);
    align-items: center;
    margin: 0;
}

.transcript-details-accordion-header h2 {
    margin: 0;
    font-size: var(--font-size-28);
    font-weight: var(--font-weight-400);
}

.transcript-details-accordion .MuiButtonBase-root.MuiAccordionSummary-root {
    padding: 0;
}

.term-row {
    padding: var(--constant-spacing-8);
    background-color: var(--accent-green-dark);
}

.term-row p {
    margin: 0;
    padding-left: var(--constant-spacing-8);
    color: var(--text-white);
    font-weight: var(--font-weight-700);
}

.course-data p {
    margin: 0;
}

.letter-grade {
    font-weight: var(--font-weight-700);
}

.MuiTableCell-root.grade-cell {
    font-weight: var(--font-weight-700);
}