.custom-alert-container {
    display: flex;
    align-items: flex-start;
    gap: var(--constant-spacing-8);
    padding: var(--constant-spacing-16);
    border-radius: var(--border-radius-4);
    border-width: 1px;
    border-style: solid
}

.custom-alert-content {
    padding: var(--constant-spacing-4) 0;
}

.custom-alert-content h3 {
    margin-top: 0;
    font-size: var(--font-size-16);
}

.custom-alert-container.warning {
    background-color: var(--warning-background);
    border-color: var(--warning-main)
}

.custom-alert-container.error {
    background-color: var(--error-background);
    border-color: var(--error-dark)
}

.custom-alert-container.success {
    background-color: var(--success-background);
    border-color: var(--success-dark)
}

.custom-alert-container.info {
    background-color: var(--info-background);
    border-color: var(--info-dark)
}