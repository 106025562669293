.multiselect-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--constant-spacing-16);
}

.multiselect-dropdown.in-school-config {
    display: flex;
    flex-direction: column;
}

.multiselect-dropdown.in-school-config .dropdown-title {
    align-self: flex-start;
}

.dropdown-title {
    font-size: 16px;
}