.cte-details-container {
  display: flex;
  flex-direction: column;
  gap: var(--scaling-spacing-16);
  padding-top: 0;
}

.flow-diagram-details-container {
  display: flex;
  flex-direction: column;
  gap: var(--scaling-spacing-16);
}

.pathways-selection {
  display: flex;
  align-items: center;
  gap: var(--scaling-spacing-16);
}

.flow-diagram-container {
  width: 100%;
  height: 20vh;
  background-color: var(--background-green);
  padding: var(--scaling-spacing-16);
}
