.dialog-title-container {
  display: flex;
  align-items: center;
  padding: var(--scaling-spacing-24) var(--scaling-spacing-24) 0;
}

.dialog-title-container h2 {
  font-size: var(--font-size-24);
}

.dialog-title-container .close-button {
  margin-left: auto;
  padding: 0;
  min-width: 0;
}

.custom-modal-actions-container {
    display: flex;
    justify-content: flex-end;
    gap: var(--scaling-spacing-16);
}