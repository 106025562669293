.transcript-details-content {
    padding-top: 0;
    background-color: var(--background-white);
}

.transcript-details-content .transcript-details-accordion {
    background-color: var(--background-white);
}

.bottom-page-buttons {
    display: flex;
    justify-content: space-between;
    padding: var(--constant-spacing-24);
    background-color: var(--background-white);
}