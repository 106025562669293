.upload-statuses-container {
    padding: var(--scaling-spacing-24);
    margin: var(--scaling-spacing-16) 0;
    background-color: var(--background-green);
}
  
.upload-status {
    display: flex;
    align-items: center;
    gap: var(--scaling-spacing-8);
    font-size: var(--font-size-14);
}

.upload-status + .upload-status {
    margin-top: var(--scaling-spacing-16);
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid var(--divider-10);
}

.table-header .table-row {
    text-align: center;
    border-bottom: 1px solid #000000;
    font-weight: var(--font-weight-700);
}

.table-body .table-row {
    text-align: center;
    border-top: 1px solid var(--divider-10);
}

.table-body .align-left {
  text-align: left;
}

.table th,
.table td {
    padding: var(--scaling-spacing-16) var(--scaling-spacing-8);
    overflow-wrap: normal;
    width: 20%;
}