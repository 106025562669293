.matching-status-info {
  display: flex;
  align-items: flex-start;
  gap: var(--scaling-spacing-4);
  padding: var(--constant-spacing-8) 0;
}

.matching-status-info.alignment-fix {
  display: grid;
  grid-template-columns: min-content auto;
}

.matching-status-info.alignment-fix .info-text {
  align-self: center;
}

.matching-status-info .status-info-label {
  width: 10rem;
}

.matching-status-info .matching-error {
  color: var(--error-dark);
}