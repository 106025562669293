.side-drawer-container {
  border-radius: var(--border-radius-20);
  border: 1px solid var(--border-color);
  background-color: var(--background-white);
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: var(--constant-spacing-24);
}

.side-drawer-container.closed {
  display: none;
}

.side-drawer-header {
  display: flex;
  align-items: center;
  border-top-left-radius: var(--border-radius-20);
  border-top-right-radius: var(--border-radius-20);
  background-color: var(--main-light);
  gap: var(--constant-spacing-16);
}

.side-drawer-header p {
  margin: 0;
  font-size: var(--font-size-24);
}

.side-drawer-header .close-icon {
  margin-left: auto;
}

.side-drawer-content {
  display: flex;
  flex-direction: column;
}

.drawer-section {
  padding-top: var(--scaling-spacing-24);
}

.drawer-section.in-transcript-selection {
  padding-top: 0;
}

.drawer-section:last-of-type {
  padding-bottom: 0;
}

.drawer-section.transcripts-details-chart-section {
  padding-bottom: var(--scaling-spacing-24);
}

.drawer-section .chart-title {
  margin: 0;
  font-size: var(--font-size-14);
}

.drawer-section .chart-sub-title {
  margin: 0;
  font-size: var(--font-size-12);
}

.drawer-section .section-title {
  margin-top: 0;
  font-size: var(--font-size-20);
}

.drawer-section .section-title.in-transcript-selection {
  margin-top: var(--constant-spacing-16);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-700);
}

.average-data-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--constant-spacing-16);
  margin-bottom: var(--constant-spacing-16);
}