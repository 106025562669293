.drawer-section {
  padding: var(--scaling-spacing-24) 0;
}

.drawer-section.chart-section {
  padding-bottom: 0;
}

.drawer-section .section-title {
  margin-top: 0;
}

.side-drawer-matching-status {
  align-items: center;
  border-bottom: 1px solid var(--divider-5);
}

.drawer-section .chart-title {
  font-size: var(--font-size-14);
}