.table-with-pagination {
    display: flex;
    flex-direction: column;
}

.table-with-pagination .pagination-container {
    margin: var(--constant-spacing-8) 0;
  }

.data-qc-content-table th, .data-qc-content-table td  {
    padding: var(--scaling-spacing-8) var(--scaling-spacing-16);
    word-wrap: break-word;
}

.data-qc-content-table-header .data-qc-content-table-row {
    border-bottom: 1px solid var(--border-color);
    font-weight: var(--font-weight-700);
}

.data-qc-content-table-body .data-qc-content-table-row {
    border-top: 1px solid var(--divider-10);
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}