.loader-overlay {
  position: absolute;
  z-index: 100;
  height: 100vh;
  width: 100%;
  opacity: 0.2;
  background-color: var(--text-color);
}

.loader-container-fullscreen {
  position: absolute;
  z-index: 101;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}