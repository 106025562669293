.data-qc-details-container {
  display: flex;
}

.main-content,
.data-qc-drawer-container {
  min-width: 50%;
}

.data-qc-drawer-container {
  min-height: 50vh;
  border: none;
  border-left: 1px solid var(--border-color);
  border-radius: 0;
  box-shadow: none;
}