.uploaded-file {
  padding: var(--scaling-spacing-16) 0;
  border-bottom: 1px solid var(--divider-10);
}

.file-upload-info {
  display: grid;
  grid-template-columns: min-content var(--scaling-spacing-8) auto;
  grid-template-areas: 
    "icon . file-name"
    ". . upload-info";
  align-items: center;
}

.upload-status-icon {
  grid-area: icon;
  justify-self: center;
}

.file-name {
  grid-area: file-name;
}

.file-size {
  display: block;
  font-size: var(--font-size-14);
  color: var(--text-gray);
  text-transform: uppercase;
}

.upload-info {
  display: flex;
  align-items: center;
  gap: var(--scaling-spacing-16);
  color: var(--text-gray);
  padding: var(--scaling-spacing-8) 0;
  margin-left: var(--scaling-spacing-28);
  font-size: var(--font-size-14);
  grid-area: upload-info;
}

.upload-info p {
  margin: 0;
}

.status-info {
  display: flex;
  gap: var(--scaling-spacing-4);
}

.upload-status-success {
  color: var(--success-dark);
}

.upload-status-error {
  color: var(--error-dark);
}

.file-processing-info-text {
  margin-left: var(--constant-spacing-32);
  white-space: nowrap;
  font-size: var(--font-size-12);
}

.upload-actions {
  display: flex;
  gap: var(--scaling-spacing-16);
  margin-left: auto;
}

.uploading-file-info {
  display: flex;
  justify-content: space-between;
}