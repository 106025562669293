.select-district-dropdown {
    margin-bottom: var(--scaling-spacing-16);
}

.district-select-bar {
    display: flex;
    align-items: center;
    padding: var(--constant-spacing-16) var(--constant-spacing-24);
    background-color: var(--text-black);
    color: var(--text-white);
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-600);
}

.district-select-bar .selected-district {
    margin-right: var(--scaling-spacing-16);
}

.district-select-bar button {
    padding: 0;
}