.data-qc-main-content {
    display: flex;
    flex-direction: column;
    gap: var(--constant-spacing-32);
}

.link {
    color: var(--text-accent);
    text-decoration: underline;
    font-weight: var(--font-weight-700);
}

.data-qc-cards-container {
    column-count: 3;
    gap: var(--scaling-spacing-16);
    column-width: 300px;
}

.data-qc-summary-card {
  margin-bottom: var(--constant-spacing-24);
  break-inside: avoid;
}

.matching-status-info p span {
    font-family: 'Roobert';
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-14);
}

.students-schools-count {
    display: flex;
    gap: var(--constant-spacing-16);
    font-size: var(--font-size-14);
}

.students-schools-count p span {
    font-family: 'Teodor Light';
    font-weight: 300;
    font-size: var(--font-size-24);
    margin: 0;
}