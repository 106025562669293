.navigation-link-container {
  display: flex;
  padding: var(--scaling-spacing-8);
}

.navigation-link-container.disabled {
  color: var(--text-disabled);
  pointer-events: none;
}

.navigation-link-container.active {
  position: relative;
}

.navigation-link-container:hover {
  background-color: var(--background-gray);
}

.navigation-link-container.active {
  font-weight: var(--font-weight-700);
}

.navigation-link-container.active::before {
  content: '';
  position: absolute;
  left: -4px;
  bottom: 0;
  width: 4px;
  height: 100%;
  background-color: var(--accent-green-main);
}

.navigation-link{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.sidebar.closed .navigation-link-text,
.sidebar.closed .navigation-link-container .arrow-icon {
  display: none;
}

.navigation-link-icon {
  margin-right: var(--constant-spacing-8);
  min-width: 20px;
  height: auto;
}

.sidebar.closed .navigation-link-icon {
  margin: 0;
}

.navigation-link-container .arrow-icon {
  margin-left: auto;
  width: 20px;
}

.navigation-sub-links-container {
  display: flex;
  flex-direction: column;
  padding: var(--scaling-spacing-8);
  padding-left: 0;
  margin-left: var(--scaling-spacing-32);
  font-size: var(--font-size-14);
}

.navigation-sub-links-list {
  list-style-type: none;
  padding: 0;
}

