:root {
  /* ------------------ */
  /*colors from the design system file*/

  /*accent colors*/
  --accent-green-main: #83FBA3;
  --accent-green-dark: #145758;
  --accent-green-dark-hovered: #0A3E3F;
  --accent-green-light: #BDFCD7;
  --accent-blue-main: #80B0E8;
  --accent-blue-dark: #3B71C2;
  --accent-blue-light: #CEE3EF;
  --accent-gradient-primary: linear-gradient(90deg, #719FF7 0%, #83FBA3 100%);
  --accent-gradient-secondary: linear-gradient(90deg, #7BD5DB 0%, #7FB0E8 100%);

  /*text colors*/
  --text-white: #FFFFFF;
  --text-black: #000000;
  --text-gray: #5A5A5A;
  --text-accent: var(--accent-green-dark);
  --text-disabled: #8F8F8F;
  --text-link: var(--accent-green-dark);

  /*background colors*/
  --background-white: #FFFFFF;
  --background-off-white: #FFF8EF;
  --background-gray: #F2F2F2;
  --background-medium-gray: #B1B1B1;
  --background-green: #F2FCFB;
  --background-yellow-green: #489F55;
  --background-disabled: #E5E5E5;

  /*divider colors*/
  --divider-100: #000000;
  --divider-70: #4D4D4D;
  --divider-50: #808080;
  --divider-10: #E5E5E5;
  --divider-5: #DDDDDD;

  /* border */
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-10: 10px;
  --border-radius-16: 16px;
  --border-radius-20: 20px;
  --border-radius-24: 24px;
  --border-radius-circle: 50%;
  --border-color: var(--divider-100);
  --border-color-accent: var(--text-accent);
  --border-color-gray: var(--text-gray);
  --border-color-disabled: var(--divider-10);

  --outline-color: var(--accent-blue-dark);

  /*system colors*/

  /*error colors*/
  --error-main: #DD6147;
  --error-dark: #CD4326;
  --error-light: #FFD4B5;
  --error-background: #FFECDF;

  /*warning colors*/
  --warning-main: #F4C148;
  --warning-dark: #513900;
  --warning-light: #FDFFA2;
  --warning-background: #FEFFC8;

  /*info colors*/
  --info-main: #3B71C2;
  --info-dark: #134899;
  --info-light: #CEE3EF;
  --info-background: #EDF8FF;

  /*success colors*/
  --success-main: #489F55;
  --success-dark: #096B18;
  --success-light: #C9FC8D;
  --success-background: #E9FFCE;

  /* ------------------ */

  /* ------------------ */
  /* Typography */

  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-24: 1.5rem;
  --font-size-28: 1.75rem;
  --font-size-32: 2rem;
  --font-size-36: 2.25rem;
  --font-size-64: 4rem;

  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;

  /* ------------------ */

  /* ------------------ */
  /* Spacing */

  --constant-spacing-4: 0.25rem;
  --constant-spacing-8: 0.5rem;
  --constant-spacing-12: 0.75rem;
  --constant-spacing-16: 1rem;
  --constant-spacing-24: 1.5rem;
  --constant-spacing-32: 2rem;
  --constant-spacing-40: 2.5rem;
  --constant-spacing-48: 3rem;

  --scaling-spacing-4: 0.25em;
  --scaling-spacing-8: 0.5em;
  --scaling-spacing-12: 0.75em;
  --scaling-spacing-14: 0.875em;
  --scaling-spacing-16: 1em;
  --scaling-spacing-24: 1.5em;
  --scaling-spacing-32: 2em;
  --scaling-spacing-48: 3em;

  /* ------------------ */

  --container-background-color-light: #F9FAFB;
  --container-background-color: #F3F4F6;

  --text-color-light: #6B7280;
  --text-color: #000000;

  --card-title-color: #111928;
  --box-color: #DEF7EC;

  --blue: #1C64F2;

  --green: #3F954B;
  --green-dark: #057A55;

  --red-medium: #E02424;
  --red-dark: #C81E1E;

  --main-light: #FFFFFF;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/Roobert-Regular.woff2') format('woff2'), url('./fonts/Roobert-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/Roobert-Medium.woff2') format('woff2'), url('./fonts/Roobert-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/Roobert-Bold.woff2') format('woff2'), url('./fonts/Roobert-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Teodor Light';
  src: url('./fonts/Teodor-Light.woff2') format('woff2'), url('./fonts/Teodor-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

body {
  margin: 0;

  font-family: 'Roobert', 'sans-serf';
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
}

.pointer {
  cursor: pointer;
}

.list-type-disc {
  list-style-type: disc;
}

.bolded {
  font-weight: bold;
}

.capitalized {
  text-transform: capitalize;
}

.no-margin {
  margin: 0;
}

.icon-size {
  width: var(--constant-spacing-24);
  height: var(--constant-spacing-24);
}

.color-dot {
  width: var(--constant-spacing-16);
  height: var(--constant-spacing-16);
  border-radius: var(--border-radius-circle)
}

.center-text {
  text-align: center;
}