.transcript-selection-header {
    display: flex;
    flex-wrap: wrap;
    gap: var(--constant-spacing-24);
    align-items: flex-end;
}

.transcript-selection-header .completed {
    display: flex;
    align-items: center;
    gap: var(--scaling-spacing-4);
}

.transcript-selection-header-actions {
    display: flex;
    flex-wrap: wrap;
    gap: var(--constant-spacing-24);
    margin-left: auto;
}
  
.transcript-selection-header-actions button {
    white-space: nowrap;
}

