.error-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.error-modal-title {
  font-weight: bolder;
  font-size: var(--font-size-24);
}

.error-modal-text {
  color: var(--text-color-light);
}