.divider {
  border: 1px solid var(--border-color);
}

.divider.small {
  margin: var(--scaling-spacing-8);
}

.divider.medium {
  margin: var(--constant-spacing-16);
}

.divider.large {
  margin: var(--scaling-spacing-24);
}

.divider.extra-large {
  margin: var(--scaling-spacing-32);
}

.divider.horizontal {
  margin-left: 0;
  margin-right: 0;
  border-bottom: none;
}

.divider.vertical {
  margin-top: 0;
  margin-bottom: 0;
  border-right: none;
}

.divider.light {
  border-color: var(--divider-5);
}