.helper-section {
  display: flex;
  flex-direction: column;
}

.helper-section h2 {
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-400);
  margin: 0;
}

.helper-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--scaling-spacing-8);
}

.helper-section p {
    margin-bottom: 0;
}

.icon {
  cursor: pointer;
  width: 25px;
  height: auto;
}