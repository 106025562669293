.configured-schools {
    margin-top: var(--constant-spacing-16)
}

.school-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.school-item-actions {
    display: flex;
    gap: var(--constant-spacing-8);
    cursor: pointer;
}