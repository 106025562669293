.user-profile-container {
  text-align: left;
}

.user-profile-container p {
  margin: 0;
}

.user-profile-container .user-name {
  font-weight: var(--font-weight-500);
  margin-bottom: var(--scaling-spacing-4);
}

.user-profile-container .user-role {
  font-size: var(--font-size-12);
  color: var(--text-gray);
}