.modal-body .modal-body-content {
  color: var(--text-color-light);
}

.modal-actions {
  display: flex;
  gap: var(--constant-spacing-16);
  align-items: center;
  margin: var(--constant-spacing-16) 0;
}

.modal-actions .modal-actions-help {
  color: var(--blue);
  cursor: pointer;
  margin-right: auto;
}