.page-header-container {
  background-image: var(--accent-gradient-primary);
  padding: var(--scaling-spacing-32) var(--scaling-spacing-48);
}

.page-header-container h1 {
  font-weight: var(--font-weight-400);
}

.page-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--scaling-spacing-16);
}

.page-header-title {
  margin: 0;
}

.page-description {
  margin-bottom: 0;
}