.card-container {
  padding: var(--constant-spacing-24);
  border-radius: var(--border-radius-24);
  background: var(--main-light);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
}

.card-container-header {
  display: flex;
  justify-content: space-between;
}

.card-container-header.centered {
  justify-content: center;
}

.card-container-title {
  display: flex;
  align-items: center;
  gap: var(--constant-spacing-4);  
}

.card-container-title p {
  margin: 0;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-700);
}