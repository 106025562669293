.transcript-selection-tables-container {
  display: flex;
  flex-direction: column;
  gap: var(--constant-spacing-32);
  margin-top: var(--constant-spacing-32);
}

.transcript-selection-category-container {
  background-color: #FFFFFF;
  border-radius: var(--border-radius-20);
  padding: var(--scaling-spacing-24);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.transcript-selection-category-top-section p {
  margin: 0;
}

.transcript-selection-category-header .title {
  display: flex;
  align-items: center;
  gap: var(--constant-spacing-4);
  margin-bottom: var(--constant-spacing-8);
}

.transcript-selection-category-header .title p { 
  font-size: var(--font-size-24);  
}

.transcript-selection-category-header-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: var(--constant-spacing-24) 0;
}

.transcript-selection-category-header-actions .selection-error-message {
  color: var(--error-dark);
}

.transcript-selection-category-header-actions .buttons-container {
  display: flex;
  gap: var(--constant-spacing-16);
  margin-left: auto;
}

.transcript-selection-category-header-actions button {
  white-space: nowrap;
}

.transcripts-view .transcripts-view-header {
  display: flex;
  align-items: center;
  gap: var(--constant-spacing-16);
}

.transcripts-view .transcripts-view-header h2 {
  margin: 0;
}

.transcripts-view .transcripts-view-body {
  margin-top: var(--constant-spacing-16);
}