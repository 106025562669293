.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--constant-spacing-24);
  padding: var(--constant-spacing-48);
  background-color: var(--main-light);
  border-top: 1px solid var(--border-color);
  margin-top: auto;
}

.footer.login {
  width: 100vw;
}

.footer-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--constant-spacing-40);
}

.footer-link-container {
  display: flex;
  align-items: center;
  gap: var(--constant-spacing-4)
}

.footer-link {
  color: var(--text-link);
  font-weight: var(--font-weight-700);
  text-decoration: underline;
  cursor: pointer;
}

.copyright {
  margin: 0;
  font-size: var(--font-size-12);
}