.transcript-details-header-content {
    padding: var(--constant-spacing-32) var(--constant-spacing-48);
}

.transcript-details-header-content button:first-of-type {
    padding-left: 0;
}

.transcript-details-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--constant-spacing-8);
}

.transcript-details-header h2 {
    margin: 0;
    font-size: var(--font-size-36);
    font-weight: var(--font-weight-400);
}

.transcript-details-header .tag {
    font-size: var(--font-size-16);
}

.transcript-details-header .buttons-container {
    display: flex;
    align-items: center;
    gap: var(--constant-spacing-16);
    margin-left: auto;
}

.transcript-details-summary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: var(--constant-spacing-8);
    column-gap: var(--constant-spacing-32);
}

.transcript-details-summary .category {
    display: flex;
    align-items: center;
    gap: var(--constant-spacing-8)
}

.transcript-details-summary p {
    margin: 0;
    font-weight: var(--font-weight-700);
}

.category .category-value {
  text-transform: capitalize;
}