.upload-prompt-error-message .error-message-text {
  display: flex;
  align-items: flex-start;
  gap: var(--scaling-spacing-8);
  color: var(--error-dark);
  margin: var(--scaling-spacing-16) 0;
}

.upload-prompt-error-message .error-message-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--scaling-spacing-16);
}