.upload-card {
  border-radius: var(--border-radius-20);
}

.upload-card-header {
  display: flex;
  flex-wrap: wrap;
  gap: var(--scaling-spacing-8);
  align-items: center;
  margin: 0;
}

.upload-card-header h2 {
  margin: 0;
  font-weight: var(--font-weight-400);
}

.upload-card-header button {
  margin-left: auto;
}

.upload-card-helper-text {
  margin-bottom: var(--scaling-spacing-16);
}

.upload-card-helper-text p {
  margin-top: 0;
}

.upload-card-actions {
    display: flex;
    align-items: center;
}

.upload-card-content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--constant-spacing-32);
    margin: var(--constant-spacing-24) 0;

    container-type: inline-size;
}

@container (min-width: 850px) {
    .upload-card-drop-zone+.uploaded-files {
        border-left: 1px solid var(--divider-100);
        padding-left: var(--scaling-spacing-24);
    }
}

.upload-card-drop-zone,
.uploaded-files {
  flex: 1 1 400px;
}

.drop-zone {
  margin: auto;
  text-align: center;
  background-color: var(--background-white);
  border: 1px dashed var(--divider-70);
  border-radius: var(--border-radius-20);
  padding: var(--scaling-spacing-32);
  cursor: pointer;
}

.drop-zone.drag-active {
  background-color: var(--container-background-color);
}

.drop-zone-text {
  margin: 0;
  margin: var(--scaling-spacing-8) 0;
}

.drop-zone-helper-text-container {
  margin-top: var(--scaling-spacing-14);
}

.drop-zone-helper-text {
  margin: 0;
  font-size: var(--font-size-12);
}

.link-color {
  color: var(--text-link);
  text-decoration: underline;
  font-weight: var(--font-weight-700);
}

.upload-icon {
  width: 40px;
  height: auto;
}

.separator {
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  margin: var(--constant-spacing-16);
}

.separator-text {
  background-color: white;
  position: relative;
}

.separator-text:before {
  content: '';
  width: 100px;
  height: 2px;
  top: 8px;
  left: -110px;
  position: absolute;
  background-color: var(--border-color);
}

.separator-text:after {
  content: '';
  width: 100px;
  height: 2px;
  top: 8px;
  right: -110px;
  position: absolute;
  background-color: var(--border-color);
}

.sign-in-button {
  background-color: var(--main-light);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-20);
  padding: var(--scaling-spacing-8) min(20%, 150px);
  display: block;
  margin: auto;
}