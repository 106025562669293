.course-catalogs-details .header {
  display: flex;
  gap: var(--constant-spacing-16);
}

.subject {
  font-size: var(--font-size-20);
}

.courses {
  margin-bottom: var(--constant-spacing-16);
}

.courses p span, .header p span {
  margin-right: var(--constant-spacing-8);
  font-family: 'Teodor Light';
  font-weight: 300;
  font-size: var(--font-size-24);
}