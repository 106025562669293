.overview-district-card {
  background-color: var(--background-white);
  padding: var(--scaling-spacing-24);

  border-radius: var(--border-radius-20);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.overview-district-card p {
  margin: 0;
}

.overview-district-card .card-title {
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-700);
}

.overview-district-card .upload-progress-sections {
  margin-top: var(--scaling-spacing-24);
  display: flex;
  flex-wrap: wrap;
}

.upload-progress-sections .upload-progress-section {
  min-width: 300px;
  flex: 1 1 300px;
}

.upload-progress-section .section-header {
  display: flex;
  align-items: center;
  gap: var(--scaling-spacing-8);
  margin-bottom: var(--scaling-spacing-16);
}

.header-title .progress-status {
  font-size: var(--font-size-20);
}

.header-title .categories-count {
  font-size: var(--font-size-12);
}

.upload-progress-section .categories {
  list-style-type: none;
  padding: 0;
}

.categories .category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--scaling-spacing-16) 0;
  border-bottom: 1px solid var(--divider-10);
}

.category .upload-progress-info {
  margin-top: var(--scaling-spacing-8);
}

@media only screen and (max-width: 1400px) {
  .overview-district-card .upload-progress-sections {
    flex-direction: column;
  }

  .upload-progress-sections .upload-progress-section {
    flex: 1 1 auto;
  }

  .upload-progress-sections .rotating-divider {
    margin: var(--scaling-spacing-32) 0;
  }
}