.demographics-content {
  display: flex;
  gap: var(--constant-spacing-24);
}

.demographics-content-section {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  gap: var(--constant-spacing-24);
  margin-top: var(--constant-spacing-16)
}

.demographics-content-section-header {
  display: flex;
  flex-direction: column;
  gap: var(--constant-spacing-8)
}

.demographics-content-section-header {
  font-size: var(--font-size-24);
  font-weight: 400;
  /* hardcoded height to avoid misalignment when text wraps on multiple lines on smaller screens */
  height: 50px;
}

.demographics-content-section-header .header-description {
  font-size: var(--font-size-14);
}

.students-count-info {
  /* hardcoded height to avoid misalignment when text wraps on multiple lines on smaller screens */
  height: 70px;
}

.students-count,
.bar-percentages p {
  font-family: 'Teodor Light';
  font-weight: 300;
}

.students-count {
  font-size: var(--font-size-64);
  margin-right: var(--constant-spacing-8);
}

.students-count-description {
  display: inline-block;
  width: 50%;
  max-width: 300px;
  font-size: var(--font-size-14);
}

.demographics-content .chart-title {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-700);
}

.lollipop-chart-container {
  position: relative;
  width: 99%;
}

.bars {
  display: flex;
  margin: var(--constant-spacing-8) 0;
  border-radius: var(--border-radius-4);
  overflow: hidden;
  height: 32px;
}

.bar-percentages {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.legend {
  display: flex;
  align-items: center;
  gap: var(--constant-spacing-8);
}

.legend span {
  font-size: var(--font-size-20);
  font-family: 'Teodor Light';
  font-weight: 300;
}

.legend .gender {
  font-size: var(--font-size-16);
  font-family: 'Roobert';
}

.other-data {
  display: flex;
  flex-direction: column;
  gap: var(--constant-spacing-16);
}

.socioeconomic-disability-status {
  display: flex;
  gap: var(--constant-spacing-16);
}

.demographics-content-statistics .header-text {
  font-family: 'Roobert';
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-700);
}

.socioeconomic-disability-status .line,
.socioeconomic-status,
.disability-status {
  flex: 1;
}

.socioeconomic-disability-status.small-screen,
.bar-percentages.small-screen {
  flex-direction: column;
}

.el-native-status {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: var(--constant-spacing-16);
}

.other-data .header {
  display: flex;
  gap: var(--constant-spacing-8);
  align-items: center;
  margin-bottom: var(--constant-spacing-8);
}

.other-data .header .line {
  flex-grow: 1;
  height: 1px;
  background-color: var(--divider-100);
}

.other-data p {
  margin: 0;
  text-align: center;
}

.cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: var(--constant-spacing-4);
  padding: var(--constant-spacing-16);
  background-color: var(--background-off-white);
  border-radius: var(--border-radius-8);
  border: 1px solid var(--divider-10);
}

.cell.all {
  background-color: var(--background-gray);
}

.cell .cell-value {
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-500);
}

.cell span {
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-400);
}