.insufficient-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.insufficient-data-container img {
  background-color: var(--background-gray);
  padding: var(--constant-spacing-8);
  border-radius: var(--border-radius-circle);
}

.insufficient-data-container p {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-700);
}