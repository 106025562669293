.review-selection-category {
  margin-top: var(--scaling-spacing-24);
}

.review-selection-category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-type-name {
  text-transform: capitalize;
}

.review-selection-category-header p {
  margin: 0;
}

.file-type-name, .review-selection-category-header p {
  font-size: var(--font-size-18);
}

.review-selection-step .category-selections {
  margin: 0;
  padding-left: var(--scaling-spacing-8);
  list-style-type: none;
}

.category-selections .selected-value {
  margin-top: var(--scaling-spacing-16);
}

.review-selection-step .step-button {
  margin-top: var(--scaling-spacing-24);
  margin-left: auto;
}