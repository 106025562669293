.upload-selection-step .step-description {
  margin: var(--scaling-spacing-16) 0;
}

.upload-selection-step .step-label {
  text-transform: lowercase;
}

.upload-selection-step .step-button {
  margin-top: var(--scaling-spacing-16);
  margin-left: auto;
}