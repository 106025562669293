.overview-step-card {
  min-width: 350px;
  flex: 1 1 350px;

  background-color: var(--background-white);
  padding: var(--scaling-spacing-24);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-700);
  border-radius: var(--border-radius-20);

  display: grid;
  grid-template-columns: 10% auto;
  grid-template-rows: min-content auto auto;
  grid-template-areas: 
    "icon header"
    ". description"
    ". button"
  ;
  grid-gap: var(--scaling-spacing-16);
  align-items: center;
  justify-items: start;
}

.overview-step-card.active {
  background-color: var(--accent-green-light);
}

.overview-step-card p {
  margin: 0;
}

.overview-step-card img {
  grid-area: icon;
  height: 100%;
  width: auto;
  justify-self: center;
}

.overview-step-card .card-header {
  grid-area: header;
}

.card-header .card-title {
  font-size: var(--font-size-24);
}

.overview-step-card .card-description {
  grid-area: description;
  font-weight: var(--font-weight-400);
  max-width: 600px;
}

.overview-step-card .card-button {
  grid-area: button;
}