.upload-results-title {
  margin-top: var(--constant-spacing-16);
  margin-bottom: 0;
  font-size: var(--font-size-14);
  font-weight: 700;
}

.result-container {
  padding: var(--scaling-spacing-8);
}

.result-container.failure {
  color: var(--error-dark);
}

.result-status img{
  width: 24px;
}

.result-status p {
  margin: 0;
}

.result-status {
  display: flex;
  align-items: center;
  gap: var(--constant-spacing-8);
}

.files-metadata {
  display: flex;
  gap: var(--constant-spacing-8);
}

.missing-columns {
  margin: var(--constant-spacing-8) var(--constant-spacing-16);
}
  
.files-metadata-section {
  margin-top: var(--constant-spacing-16);
  padding: var(--constant-spacing-16);
  background-color: var(--background-gray);
}

.files-metadata-section p {
  margin: 0;
  font-weight: 400;
}

.files-metadata-section h4 {
  margin: var(--constant-spacing-16) 0;
  font-size: var(--font-size-14);
  font-weight: 700;
}

.meta-data-container {
  width: 50%;
}

.file-metadata {
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-auto-flow: column;
  column-gap: var(--scaling-spacing-8);
  row-gap: var(--scaling-spacing-16);
}

.file-metadata p {
  font-size: var(--font-size-14);
}
