.sidebar {
  font-size: var(--font-size-14);
}

.sidebar.big-screen {
  width: 25%;
  max-width: 250px;
  border-right: 1px solid var(--border-color);
  transition: width 0.5s ease;
}

.sidebar.small-screen {
  width: 250px;
}

.sidebar.closed {
  overflow: hidden;
  width: auto;
  min-width: 70px;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  padding: var(--scaling-spacing-16);
  width: 100%;
  margin: 0;
  list-style-type: none;
}

.sidebar.closed .navigation-sub-links-container,
.sidebar.closed .navigation-link-container,
.sidebar.closed .divider,
.sidebar.closed .navigation-filter {
  display: none;
}

.sidebar.closed .navigation-link-container.arrow-back {
  display: flex;
}

.navigation-link-container.arrow-back {
  margin-left: auto;
}