.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top-bars-container {
  max-height: 20%;
}

.top-navigation {
  background-color: var(--background-white);
  display: flex;
  padding: var(--scaling-spacing-16);
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}

.logo-container {
    display: flex;
    align-items: center;
    color: var(--green);
}

.logo-container img {
    margin-right: var(--scaling-spacing-12);
}

.layout-container {
    display: flex;
    flex-grow: 1;
    height: 80%;
}

.sidebar-overlay-open-button-container {
  padding: var(--constant-spacing-16);
  border-right: 1px solid var(--border-color);
}

.main-content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 75%;
  background-color: var(--background-green);
  position: relative;
  overflow: auto;
}

.main-content {
  padding: var(--scaling-spacing-48);
}