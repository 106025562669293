.cte-details-list {
  margin-top: var(--scaling-spacing-16);
  margin-left: var(--scaling-spacing-32);
  display: flex;
  flex-direction: column;
  gap: var(--scaling-spacing-8);
  font-size: var(--font-size-14);
}

.cte-details-list.compact {
  margin-top: 0;
}

.cte-details-list p {
  margin: 0;
}

.cte-details-list .accent-text {
  font-family: 'Teodor Light';
  font-size: var(--font-size-24);
  margin-right: var(--constant-spacing-8);
  display: inline-block;
  width: 15%;
  text-align: right;
}

.cte-details-list .item-label {
  display: inline-block;
  width: 75%;
}