.transcript-selection-side-drawer {
  flex-grow: 1;
  min-width: 40%;
  width: 400px;
  border: none;
  border-left: 1px solid var(--border-color);
  border-radius: 0;
  box-shadow: none;
}

.transcript-selection-overlay-content {
  padding: var(--constant-spacing-24);
}

.transcript-selection-chart-legend {
  display: flex;
  gap: var(--constant-spacing-16);
  white-space: nowrap;
}

.transcript-selection-chart-legend-label {
  display: flex;
  gap: var(--scaling-spacing-8);
}

.transcript-selection-chart-legend-label p {
  margin: 0;
}

.transcript-selection-chart-legend-label img {
  width: 10px;
}