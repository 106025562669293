.test-scores-content {
    display: flex;
    flex-direction: column;
    gap: var(--constant-spacing-16);
    margin-top: var(--constant-spacing-16);
}

.test-scores-content p {
    margin: 0;
 }

.test-scores-test-types p span {
    font-family: 'Teodor Light';
    font-weight: 300;
    font-size: var(--font-size-24);
}

.test-scores-section-title {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-700);
}